import React, { useState } from 'react';
import axios from 'axios';
import '@fontsource/inter-tight/400.css';  // Regular weight
import '@fontsource/inter-tight/600.css';  // Semibold weight
import './App.css';

function App() {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    task: '',
    firstSentence: '',
    voice: 'maya'
  });
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await axios.post('https://api.bland.ai/v1/calls', {
        phone_number: formData.phoneNumber,
        task: formData.task,
        first_sentence: formData.firstSentence,
        voice: formData.voice,
        wait_for_greeting: true,
      }, {
        headers: {
          'Authorization': 'org_9f629f1a8e925e5c3de3de082a646a5d8bdf1ece173936407f7ef6ef3be28b942b46b576871443f48bab69',
          'Content-Type': 'application/json'
        }
      });
      
      setResponse(response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <div className="form-container">
        <h1>www.aiprank.xyz</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number (E.164 Format)"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
          
          <input
            type="text"
            name="task"
            placeholder="Call Task/Scenario"
            value={formData.task}
            onChange={handleInputChange}
            required
          />
          
          <input
            type="text"
            name="firstSentence"
            placeholder="First Sentence"
            value={formData.firstSentence}
            onChange={handleInputChange}
          />
          
          <select
            name="voice"
            value={formData.voice}
            onChange={handleInputChange}
          >
            <option value="maya">Maya</option>
            <option value="john">John</option>
            <option value="emma">Emma</option>
            <option value="alex">Alex</option>
          </select>
          
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Initiating Call...' : 'Start Prank Call'}
          </button>
        </form>
        
        {error && (
          <div className="error">
            {error}
          </div>
        )}
        
        {response && (
          <div className="success">
            <h2>Call Initiated Successfully!</h2>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
